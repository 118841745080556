.navbar {
  background-color: black;
  width: 100%;
  height: 60px;
  .navbar-link {
    color: $link;
    padding-right: 10px;
    &:hover {
      color: white;
    }
  }
}


.markmuyuela {
  color: white;
  font-weight: bolder;
  text-decoration: none;
}

.header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.email {
  max-height: 60px;
  opacity: 0.8;
    &:hover {
      opacity: 1;
    }
}

  .linkedIn {
    max-height: 35px;
    padding-right: 15px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }


  .github {
  max-height: 30px;
  opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }

@media screen and (max-width: 767px) {
  .navbar-link {
    text-align: center;
    background-color: blueviolet;
    border-radius: 5px;
  }
  .header-icons {
    background-color: blueviolet;
    border-radius: 5px;
    opacity: 1;
  }
  .navbar-main.container {
    display:flex;
    justify-content: flex-end;
  }
}
