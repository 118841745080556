.skills-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: url('./images/projects-skills.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height:calc(100vh - 60px - 0px);
  .skills-title {
    margin: 5vh;
    font-weight: bolder;
  }
}

@media (min-width: 768px) {
  .skills-main {
  height: 100%;
  }
}



// tech and skills -----------------------------------------------------
.icon-main {
  display: flex;
  justify-content: center;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px; 
  margin-left: 1%;
  margin-right: 1%;
  max-width: 800px;
  border-radius: 10px;
  background-color: black;
  margin-bottom: 30vh;
}

.icon-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.2);
    .tag-title {
    opacity: 1;
    }
  }
}

.icon-image {
  max-height:60px;
  margin: 0px 20px 0px 20px;
}


