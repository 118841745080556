.about-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  color:white;
  background-image: url('./images/interests.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .about-text {
    max-width: 60vw;
    padding-top: 10px;
    
  }
}

.about-header {
  color: black;
  font-weight: bolder;
  padding-top: 3vh;
}

.about-link-container {
  display: flex;
  justify-content: space-between;
}

.about-link {
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  color: $container;
  padding-top: 15px;
  padding: 2vw;
  font-weight: bold;
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.2);
    color: black;
  }
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $container;
  padding: 20px;
  padding-top: 60px;
  border-radius: 10px;
  .about-image {
    max-width: 50vw;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.about-title {
  text-align: center;
}

.interests {
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.interests-header {
  color: black;
  font-weight: bolder;
  margin: 30px;

}

.print {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $container;
  padding: 20px;
  border-radius: 10px;
  .print-image {
    max-width: 50vw;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.tabletop {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $container;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  .tabletop-image {
    max-width: 50vw;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.bikepacking {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $container;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  .bikepacking-image {
    max-width: 50vw;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.blurb {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $container;
  max-width: 62vw;
  text-align: justify;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  .insta{
    display:flex;
    align-items: flex-end;
    .insta-text {
      padding-right: 20px;
    }
    .instagram {
      max-width: 50px;
      transition: all .2s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}