.notFound-container {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  height:100vh;
  width: 100vw;
  background-image: url('./images/loop-hyper-loop.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.notFound-nav {
  color: white;
  text-decoration: none;
  font-size: 20px;
  color: rgba(255,255,255,0.7);
  font-weight: bold;
  &:hover {
    color: white;
  }
}

.notFound-header {
  text-align: center;
}