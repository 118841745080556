.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  color: white;
  position: fixed;
  bottom: 0;
  .footer-links {
    text-decoration: none;
    padding-right: 2vw;
    padding-top: 5px;
    color: $link;
    &:hover {
      color: white;
    }
  }
}