@import './variables.scss';
@import './About.scss';
@import './Home.scss';
@import './NotFound.scss';
@import './PageFooter.scss';
@import './PageNavbar.scss';
@import './Projects.scss';
@import './skills.scss';
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Roboto&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}



.tag-title {
  opacity: 0;
}


//mobile landscape mode ------------------
@media (max-width:996px) {
  .home-text {
    width: 50vw;
  }
}

// mobile portrait mode ------------------
@media (max-width: 996px) {
  .tag-title {
    opacity: 1;
  }

  .home-text {
    min-width: 50vw;
    height: 100%;
    padding-top: 10px;
  }
  .project-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px;
    padding-top: 10px;
  }
  .project-links-container {
    max-width: 280px;
  }

  .project-image {
    padding: 0px;
  }
  .skills-main {
    height:100%;
  }
  .skills-title {
    text-align: center;
  }
  .project-link-text {
    opacity: 1;
  }
}
